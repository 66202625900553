import * as React from "react";
import Styled from "styled-components/macro";
import Media from "@uizard-io/styled-media-query";
import Content from "../../layout/Content/Content";
import BasicCard, { BasicCardContainer } from "../../cards/BasicCard";

const Columns = Styled.div`
  display: flex; 
  justify-content: space-between;
  
  ${Media.lessThan("600px")`
    flex-direction: column;
  `} 
  
  ${BasicCardContainer} {
    margin-bottom: 3rem;
    padding: 9rem;
    
    ${Media.lessThan("1024px")`
        padding: 3rem;
    `} 
  }
`;

const Column = Styled.div`
  width: calc(50% - 1.45rem);
  
  ${Media.lessThan("600px")`
    width: 100%;
  `}
`;

export default () => (
  <Content>
    <Columns>
      <Column>
        <BasicCard
          icon="magic"
          headline="Generate"
          description="Kickstart your latest app or web design project like never before with the power of AI.
          Scan in screenshots and sketches and transform them into editable designs. Uizard does the hard work, so you don't have to."
        />

        <BasicCard
          icon="transform"
          headline="Iterate"
          description="Update and iterate on your designs rapidly with Uizard's AI features.
          Generate copy suggestions with Text Assistant, import UI themes from other apps with Theme Generator, adapt your designs with our easy-to-use editor."
        />
      </Column>

      <Column>
        <BasicCard
          icon="team"
          headline="Collaborate"
          description="Work with your team rapidly in real-time to bring your vision to life.
          Share your designs with team members and stakeholders in a flash. Working collaboratively has never been so easy."
        />

        <BasicCard
          icon="ai"
          dark
          headline="AI is here"
          description="Machine Intelligence is transforming the way people work whilst enhancing human creativity like never before.
          Learn how we use AI at Uizard to make design rapid, collaborative, and accessible to all."
          cta="AI research at Uizard"
          ctalink="/research/"
        />
      </Column>
    </Columns>
  </Content>
);
