import * as React from "react";
import Styled from "styled-components/macro";
import { graphql } from "gatsby";
import Meta from "../../components/meta";
import Media from "@uizard-io/styled-media-query";
import Layout from "../../components/layout/Layout";
import Button from "../../components/buttons/Button";
import AiLanding from "../../components/pages/ai/AiLanding";
import AiCards from "../../components/pages/ai/AiCards";
import Content from "../../components/layout/Content/Content";
import Description from "../../components/texts/Description";
import ResearchMagic from "../../components/pages/research/ResearchMagic";
import Shoutout from "../../components/pages/signup/Shoutout";
import HeaderBanner from "../../components/pages/ux-design/HeaderBanner";
import Headline from "../../components/texts/Headline";
import Tagline from "../../components/texts/Tagline";
import SectionWithRightVideoComingSoon from "../../components/pages/solutions/SectionWithRightVideoComingSoon";
import SectionWithLeftVideoDark from "../../components/pages/solutions/SectionWithLeftVideoDark";
import SectionWithVideo from "../../components/pages/solutions/SectionWithVideo";
import SectionWithRightVideo from "../../components/pages/solutions/SectionWithRightVideo";
import BasicCard, {
  BasicCardContainer,
  ExtendedHeadline } from "../../components/cards/BasicCard";
import BlogHighlight from "../../components/pages/blog/BlogHighlight";

import blogThumbnail2 from "../../components/pages/blog/BlogThumbnails/blog_comprehensive_AI_design_tool.png";
import blogThumbnail3 from "../../components/pages/blog/BlogThumbnails/blog_convert_screenshots.png";
import blogThumbnail1 from "../../components/pages/blog/BlogThumbnails/blog_guide_to_ai_features.png";


const Column = Styled.div`
  position: relative;
  
  ${Media.lessThan("small")`
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.lessThan("medium")`
    width: 50%;
    
    &:first-of-type {
      margin-right: 3rem;
    }
    
    &:last-of-type {
      width: 32rem;
    }
    `}
   
  ${Media.lessThan("660px")`
    width: 100%;
    margin: 0 auto;
    
    &:last-of-type {
      width: 100%;
    }
  `}
  
  ${Media.greaterThan("medium")`
    width: 45%;
    min-width: 0;
    padding: 0 3rem;
  `}

  ${Media.greaterThan("large")`
    padding: 0 6rem;
  `}
`;

const ColumnText = Styled(Column)`
  margin: 8rem 0 10rem 0;
  padding: 0;
  width: 100%;

  ${Media.greaterThan("medium")`
    display: flex;
    justify-content: space-evenly;
  `}
`;

const ExtendedButton = Styled(Button)`
  ${Media.lessThan("medium")`
    margin-bottom: 3rem;
    width: 100%;
  `}
`;

const NavButtonLink = Styled.a`
`;

const Container = Styled.div`
  width: 100%;
  text-align: center;
  margin: 16rem 0 10rem 0;
  padding: 0 9rem;
  
  ${Media.lessThan("large")`
    padding: 0 4rem;
  `}
  
  ${Media.lessThan("medium")`
      margin: 11rem 0 5rem 0;
  `}
  
  ${Media.lessThan("small")`
    padding: 0;
    margin: 9rem 0 4rem 0;
  `}
`;

const ExtendedDescription = Styled(Description)`
    max-width: 120rem;
    margin: auto;
`;

// const Row = Styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;

//   ${Media.greaterThan("900px")`
//     margin-top: 25px;
//     margin-bottom: 35px !important;
//   `}
//   margin-bottom: 10px;
// `;


const Row = Styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  text-align: left;
  grid-gap: 5rem;

  & > * {
    margin-bottom: 3rem;
  }

  ${Media.lessThan("medium")`   
    display: block;
    width:90%;
  `}
 
  ${BasicCardContainer} {
    margin: 4 auto;
    text-align: left;
    align-items: left;
    margin-top: 0;
    padding: 5rem;

    ${Headline}, ${Description} {
      margin-top: 0;
      padding-top: 0;
    }
    
  }
`;

const AI = ({ data }) => (
  <Layout type="dark">
    <Meta
      title="AI Design Tool | AI UI Design | Uizard"
      description="The future of UX/UI design is here with Uizard, the world's first end-to-end AI design tool for UI design. Sign up to Uizard for free today."
      url="/ai-design/"
    />

    <AiLanding
      imageA={data.magicImageA.childImageSharp}
      imageB={data.magicImageB.childImageSharp}
      headline="AI Design"
      description="Bring your ideas to life faster than ever before with the power of AI-assisted design."
    />

    <Content>
      <Container>
        <Headline>
        AI for UI design
        </Headline>
        <ExtendedDescription>
        The future of UX/UI design is here with Uizard, the world's first AI tool for UI design. Transform screenshots to designs, convert hand-drawn sketches to wireframes, generate UI designs with simple text. App, web, and UI design has never been easier.
        </ExtendedDescription>
      </Container>
    </Content>

    <SectionWithRightVideoComingSoon
        tagline="NEW"
        headline="Uizard Autodesigner"
        description="Like ChatGPT for UX/UI design... Generate multi-screen mockups with simple text. Join the waiting list and be the first to try our <a:https://uizard.io/autodesigner/>AI UI generator</a>."
        imageA={data.textAssistantA.childImageSharp}
        imageB={data.textAssistantB.childImageSharp}
        videoName="ai-landing-autodesign"
        imageAlt="rendering a design as a low-fi wireframe or as a high-fi mockup"
        link="/autodesigner/"
    />

    <SectionWithLeftVideoDark
      headline="Uizard Screenshot"
      description="Upload screenshots and transform them into editable <a:https://uizard.io/mockups/>mockups</a> with the power of AI. Whether you're scanning screen grabs of your favorite apps, websites, or UIs, Uizard makes it easy to turn your inspiration into reality."
      imageA={data.screenshotA.childImageSharp}
      imageB={data.screenshotB.childImageSharp}
      videoName="ai-landing-screenshot"
      imageAlt="how a user can use Uizard to go from a rough wireframe to a design mockup and a polished design"
      link="/screenshot/"
    />
    
    <SectionWithRightVideo
        headline="Uizard Wireframe"
        description="Scan your hand-drawn wireframes and let Uizard's AI UI design features do the hard work. Uizard wireframe transforms your wireframe sketches into digital designs in a matter of seconds."
        imageA = {data.scannerA.childImageSharp}
        imageB={data.scannerB.childImageSharp}
        videoName="ai-landing-scanner"
        imageAlt="rendering a design as a low-fi wireframe or as a high-fi mockup"
        link="/wireframe-scanner/"
    />
    <Container>
      <Headline>Effortless UI design with the power of AI</Headline>
    </Container>

    <AiCards/>
    
    {/* <SectionWithVideo
    headline="Uizard Scanner"
    description="Scan your hand-drawn wireframes and let Uizard's AI UI design features do the hard work. Uizard Scanner transforms your wireframe sketches into digital designs in a matter of seconds, freeing up mountains of time for refining and iterating your designs."
    imageB={data.scannerA.childImageSharp}
    vidWidth={616}
    vidHeight={566}
    videoName="ai-landing-scanner"
    imageAlt="how a user can use Uizard to go from a rough wireframe to a design mockup and a polished design"
    link=""
    /> */}
    <BlogHighlight
      title="Learn more about AI design"
      blogs={[
        {
          image: blogThumbnail1,
          link: "https://uizard.io/blog/a-complete-guide-to-uizards-ai-features/",
          alt: "Thumbnail for blog titled A complete guide to Uizard's AI features",
        },
        {
          image: blogThumbnail2,
          link: "https://uizard.io/blog/ai-design-tool-stack/",
          alt: "Thumbnail for blog titled The comprehensive AI design tool stack 2023",
        },
        {
          image: blogThumbnail3,
          link: "https://uizard.io/blog/uizard-screenshot-convert-screenshots-to-mockups/",
          alt: "Thumbnail for blog titled Uizard Screenshot: Convert screenshots to editable mockups with AI",
        },
      ]}
    />
    <Content>
      <Shoutout
        dark
        headline="AI design is here"
        description="Bring your ideas to life at the speed of light with Uizard's AI UI designer"
        cta="Sign up for free"
        imageA={data.shoutoutImageA.childImageSharp}
      />
    </Content>
  </Layout>
);

export default AI;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    magicImageA: file(relativePath: { eq: "research/magic-image@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    magicImageB: file(relativePath: { eq: "research/magic-image.png" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    shoutoutImageA: file(
      relativePath: { eq: "shoutout/shoutout-image-a.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1232, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }


    textAssistantA: file(relativePath: { eq: "ai/dark-autodesigner-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }


    textAssistantB: file(relativePath: { eq: "ai/dark-autodesigner-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }


    screenshotA: file(relativePath: { eq: "ai/uizard_screenshot-a.png" }) {
      childImageSharp {
        fluid(maxWidth: 1028, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    screenshotB: file(relativePath: { eq: "ai/uizard_screenshot-b.png" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    scannerA: file(relativePath: { eq: "ai/uizard-scanner-dark-big.png" }) {
      childImageSharp {
        fluid(maxWidth: 1230, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    scannerB: file(relativePath: { eq: "ai/uizard-scanner-dark-small.png" }) {
      childImageSharp {
        fluid(maxWidth: 670, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

  }
`;
